exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-templates-channel-category-template-jsx": () => import("./../../../src/templates/channelCategoryTemplate.jsx" /* webpackChunkName: "component---src-templates-channel-category-template-jsx" */),
  "component---src-templates-column-category-template-jsx": () => import("./../../../src/templates/columnCategoryTemplate.jsx" /* webpackChunkName: "component---src-templates-column-category-template-jsx" */),
  "component---src-templates-column-template-jsx": () => import("./../../../src/templates/columnTemplate.jsx" /* webpackChunkName: "component---src-templates-column-template-jsx" */),
  "component---src-templates-post-template-jsx": () => import("./../../../src/templates/postTemplate.jsx" /* webpackChunkName: "component---src-templates-post-template-jsx" */),
  "component---src-templates-subject-category-template-jsx": () => import("./../../../src/templates/subjectCategoryTemplate.jsx" /* webpackChunkName: "component---src-templates-subject-category-template-jsx" */),
  "component---src-templates-subject-template-jsx": () => import("./../../../src/templates/subjectTemplate.jsx" /* webpackChunkName: "component---src-templates-subject-template-jsx" */),
  "component---src-templates-talk-template-jsx": () => import("./../../../src/templates/talkTemplate.jsx" /* webpackChunkName: "component---src-templates-talk-template-jsx" */)
}

